import { Permission, isAllowed, isCurrentlyValid } from "../../components/auth";

export const findCustomerById = (customerId, customers) => {
  if (customerId && Array.isArray(customers)) {
    return customers.find(customer => customer.customerId === customerId);
  }
  return null;
};

export const findShippingAddressById = (shippingAddressId, customer) => {
  if (shippingAddressId && customer) {
    return customer.deliveryAddresses.find(
      address => address.ShipTo === shippingAddressId
    );
  }
  return null;
};

export const sortAddressAlphabetically = (addressA, addressB) => {
  const a = addressA.address;
  const b = addressB.address;
  return a.localeCompare(b, undefined, { sensitivity: "accent" });
};

const removeInvalidDeliveryAddresses = customer => {
  const deliveryAddresses = (customer.deliveryAddresses || []).filter(
    isCurrentlyValid
  );

  return { ...customer, deliveryAddresses };
};

export const getDefaultDeliveryAddress = customer => {
  if (!customer) {
    return undefined;
  }
  const validDeliveryAddresses =
    removeInvalidDeliveryAddresses(customer).deliveryAddresses || [];

  return (
    validDeliveryAddresses.find(
      address => address.ShipTo === customer.customerId
    ) || validDeliveryAddresses[0]
  );
};

export const getCustomerForDefaultCart = (
  customers,
  userData,
  selectedCustomerId
) => {
  const customersWithOrderPermission = (customers || [])
    // customers that user has order edit permission to
    .filter(c =>
      isAllowed(userData, Permission.ORDER_CREATE, c.customerId, {
        customer: c,
      })
    )
    // get delivery address for all matching
    .map(c => ({
      customer: c.customerId,
      shipToAddress: (getDefaultDeliveryAddress(c, selectedCustomerId) || {})
        .ShipTo,
    }));

  // filter out those without a valid delivery address
  const customersWithOrderPermissionAndValidAddress =
    customersWithOrderPermission.filter(c => c.shipToAddress);

  let customersToSelectFrom;
  if (customersWithOrderPermissionAndValidAddress.length > 0) {
    // How it should be, there exists at least one customer for which the user has order permission
    // and it has a valid delivery address
    customersToSelectFrom = customersWithOrderPermissionAndValidAddress;
  } else if (customersWithOrderPermission.length > 0) {
    // Not so good, there are customers for which the user has order permission
    // but none of them have a valid delivery address
    // Oh well, just select one of them anyway.
    customersToSelectFrom = customersWithOrderPermission;
  } else {
    // Should not happen. There are no customers with order permission.
    // User has no order permission under any customer => Return empty array
    customersToSelectFrom = [];
  }

  // Try to find the selected customer, default to first one
  return (
    customersToSelectFrom.find(c => c.customer === selectedCustomerId) ||
    customersToSelectFrom[0]
  );
};

/**
 * Get default delivery address for customer.
 * Returns undefined if customer has no valid addresses.
 */

export const removeInvalidCustomersAndAddresses = customers => {
  // remove invalid
  const filteredCustomers = (customers || []).filter(isCurrentlyValid);

  // remove invalid addresses
  return filteredCustomers.map(customer =>
    removeInvalidDeliveryAddresses(customer)
  );
};

export const formatAddress = addr => {
  if (!addr) {
    return "";
  }
  const street = addr.Street || addr.street;
  const houseNum1 = addr.HouseNum1 || addr.houseNum1;
  const houseNum2 = addr.HouseNum2 || addr.houseNum2;
  const postCode = addr.PostCode1 || addr.postCode1;
  const city = addr.City1 || addr.city1;

  const streetAddress = [street, houseNum1, houseNum2].filter(x => x).join(" ");
  const postAddress = [postCode, city].filter(x => x).join(" ");
  return [streetAddress, postAddress].join(", ");
};

export const formatCustomerName = customer => {
  if (!customer) {
    return "";
  }

  const name1 = customer.Name1 || customer.name1 || undefined;
  const name2 = customer.Name2 || customer.name2 || undefined;

  return [name1, name2].filter(x => x).join(", ");
};
