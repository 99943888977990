import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Hidden from "@material-ui/core/Hidden";
import { Button } from "@material-ui/core";
import { ProductUtil } from "@oriola-origo/origo-common-client-lib";
import { WarningMessage } from "@oriola-origo/origo-ui-core";
// eslint-disable-next-line import/no-cycle
import CartProductTable from "../cartProductTable/cartProductTable";
import {
  cartSetNote,
  cartDelete,
  cartInit,
  cartSetCustomerAndShippingAddress,
  cartRemoveProduct,
  cartSetProductCount,
  orderMakeOrder,
  makingOrderDialogShow,
  makingOrderDialogHide,
  notificationShow,
  fetchAvailabilityStatuses,
  fetchStockStatuses,
  fetchProductPrices,
} from "../../../redux/reducers";
import { calculateCartProductOverallPrice } from "../../../utils/price/price";
import {
  findCustomerById,
  findShippingAddressById,
  removeInvalidCustomersAndAddresses,
} from "../../../utils/customer/customer";
import {
  ConfirmationPopup,
  MemoInput,
  Progress,
  CustomerAndShippingAddressSelect,
  MakingOrderDialog,
  ErrorPopup,
} from "../../generic";
import { SaveTemplatePopup } from "../../orders";
import { formatCurrency } from "../../../utils/currency/currency";
import ColdProductNotification from "../coldProductNotification/coldProductNotification";
import {
  validateCartCustomerAndShippingAddress,
  validateOrder,
  createOrder,
  localizeValidationErrors,
} from "../../../utils/cartUtil/cartUtil";
import colors from "../../../theme/colors";
import { Info } from "../../../images";
import {
  isInStock,
  getStockIdChunks,
} from "../../../utils/stockUtil/stockUtil";
import { isCustomerOrderSumValid } from "../../auth/authorization/customerRules";
import {
  getOrderingNotAvailableBetweenHours,
  isOrderingAvailableInCurrentTime,
} from "./isOrderingAvailable";

const { getLocalizedProductName } = ProductUtil;

const HTTP_BAD_REQUEST_CODE = 400;
const HTTP_NOT_FOUND_CODE = 404;
const SHOW_DELIVERY_NOTE_CODE = "Z4";

const useStyles = makeStyles(theme => ({
  products: {
    width: "100%",
  },
  productsHeader: {
    display: "flex",
    alignItems: "flex-end",
    paddingTop: theme.spacing(4),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
  },
  headerText: {
    marginRight: theme.spacing(2),
  },
  validForText: {
    color: theme.palette.text.disabled,
    fontSize: "0.875rem",
  },
  grow: {
    flexGrow: 1,
  },
  contentContainer: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
  },
  cartText: {
    color: theme.palette.text.disabled,
  },
  productsTable: {
    marginTop: theme.spacing(2),
  },
  customerAndAddressContainer: {
    width: "100%",
    marginBottom: theme.spacing(4),
  },
  deliveryNoteContainer: {
    marginBottom: theme.spacing(4),
    backgroundColor: colors.backgroundGrayLight,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  deliveryNote: {
    marginLeft: theme.spacing(1),
    color: theme.palette.text.primary,
    ...theme.typography.body1,
  },
  additionalInfoContainer: {
    marginBottom: theme.spacing(4),
  },
  additionalInfoInputContainer: {
    marginTop: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
  },
  additionalInfoTitleText: {
    color: theme.palette.text.disabled,
    marginRight: theme.spacing(2),
  },
  customerSelect: {
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: theme.spacing(0.5),
    },
  },
  inputField: {
    marginRight: theme.spacing(2),
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: theme.spacing(0),
    },
  },
  buttonsContainer: {
    paddingBottom: theme.spacing(4),
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
  },
  button: {
    textTransform: "none",
    backgroundColor: colors.backgroundGray,
  },
  buttonDisabled: {
    backgroundColor: theme.palette.text.disabled,
    color: theme.palette.text.primary,
  },
  deleteButton: {
    marginLeft: theme.spacing(4),
  },
  orderButtonEnabled: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(8),
    "&:hover": { backgroundColor: theme.palette.secondary.dark },
  },
  orderButtonDisabled: {
    marginLeft: theme.spacing(8),
  },
  progress: {
    marginLeft: theme.spacing(2),
  },
  error: {
    marginTop: theme.spacing(1),
  },
  outOfStockModal: {
    maxHeight: "300px",
    overflow: "scroll",
  },
  orderingNotAvailableWarning: {
    marginBottom: theme.spacing(3),
  },
}));

function ShoppingCart({ selectedCart, setSelectedCart }) {
  const classes = useStyles();
  const { productPricesMap, priceFetchIdMap } = useSelector(
    state => state.product
  );
  const { productStockStatusesMap, stockStatusFetchIdMap } = useSelector(
    state => state.stock
  );
  const { productAvailabilityStatusesMap, availabilityStatusFetchIdMap } =
    useSelector(state => state.availability);
  const { carts, sendingCart, cartSendError } = useSelector(
    state => state.cart
  );
  const { sendingOrder } = useSelector(state => state.order);
  const { userId, organizationIds, defaultOrganizationId } = useSelector(
    state => state.user.userData
  );
  const customers = useSelector(state => state.customer.customers);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [showSaveTemplatePopup, setShowSaveTemplatePopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showOrderConfirmationPopup, setShowOrderConfirmationPopup] =
    useState(false);
  const [errorPopup, setShowErrorPopup] = useState({
    show: false,
    title: "",
    description: "",
    saveKey: "",
    onOk: null,
    hideOkButton: false,
  });
  const [validCustomers, setValidCustomers] = useState([]);
  const [popupPos, setPopupPos] = useState({ top: 0, left: 0 });
  const [popupHeight, setPopupHeight] = useState(0);
  const [selectedCustomer, setSelectedCustomer] = useState(undefined);
  const [selectedShippingAddress, setSelectedShippingAddress] =
    useState(undefined);
  const [showDeliveryNote, setShowDeliveryNote] = useState(false);
  const [noteValue, setNoteValue] = useState("");
  const cartInitialized = useSelector(state => state.cart.initialized);
  const isOrderingCurrentlyAvailable = isOrderingAvailableInCurrentTime();

  // calculate overall prices
  const overallPrices = selectedCart
    ? calculateCartProductOverallPrice(selectedCart.products, productPricesMap)
    : { price: 0 };

  let saveTemplateRef;
  const setSaveTemplateRef = ref => (saveTemplateRef = ref); // eslint-disable-line

  useEffect(() => {
    // scroll always to top
    window.scrollTo(0, 0);
  }, []);

  // Initialize MemoInput
  useEffect(() => {
    if (selectedCart && cartInitialized) {
      // update
      const newValidCustomers = removeInvalidCustomersAndAddresses(customers);
      setValidCustomers(newValidCustomers);

      // set note
      setNoteValue(selectedCart.note);
      // check customer and shipping address and populate
      const customerId = selectedCart.customer;
      if (customerId) {
        // set customer from the valid ones (if found)
        const customer = findCustomerById(customerId, newValidCustomers);
        setSelectedCustomer(customer);

        // if there's valid customer, try to find delivery address. Note that delivery addresses
        // should be valid at this point
        if (
          customer &&
          customer.deliveryAddresses &&
          customer.deliveryAddresses.length > 0
        ) {
          const shippingAddressId = selectedCart.shipToAddress;
          const shippingAddress = findShippingAddressById(
            shippingAddressId,
            customer
          );

          setSelectedShippingAddress(shippingAddress);
        } else if (!customer) {
          setSelectedShippingAddress(null);
        }
      } else {
        setSelectedCustomer(null);
        setSelectedShippingAddress(null);
      }
    }
  }, [
    cartInitialized,
    selectedCart,
    customers,
    defaultOrganizationId,
    dispatch,
  ]);

  useEffect(() => {
    if (selectedCustomer != null) {
      // check whether the delivery note should be shown
      const { DelBlckSalesAr } = selectedCustomer;
      const showNote = (DelBlckSalesAr || "") === SHOW_DELIVERY_NOTE_CODE;
      setShowDeliveryNote(showNote);
    }
  }, [selectedCustomer, showDeliveryNote]);

  useEffect(() => {
    if (selectedCart) {
      // get customer
      const customerId = selectedCart.customer;

      // Sort products by name
      selectedCart.products.sort((a, b) =>
        getLocalizedProductName(a.product, i18n.language)?.localeCompare(
          getLocalizedProductName(b.product, i18n.language)
        )
      );

      const ids = selectedCart.products.map(
        cartProduct => cartProduct.product.materialId
      );
      if (ids.length) {
        const idChunks = getStockIdChunks(ids);
        idChunks.forEach(idChunk => {
          dispatch(fetchProductPrices(customerId, idChunk));
          dispatch(fetchStockStatuses(customerId, idChunk)).then(stockData => {
            if (!stockData) return;
            // fetch availability information for products out of stock
            const productsOutOfStock = [];
            stockData.forEach(stockStatus => {
              if (!isInStock(stockStatus)) {
                productsOutOfStock.push(stockStatus.materialId);
              }
            });
            if (productsOutOfStock.length > 0) {
              dispatch(fetchAvailabilityStatuses(customerId, idChunk));
            }
          });
        });
      }
    }
  }, [selectedCart, dispatch]); // eslint-disable-line

  if (!selectedCart) {
    return null;
  }

  const getOrderSumInfoText = () =>
    !isCustomerOrderSumValid(selectedCustomer, overallPrices.price)
      ? t("minimumOrderLimitInfo")
      : null;

  const renderHeader = cart => {
    const products = cart ? cart.products : [];
    const expirationDate = cart ? cart.expirationDate : null;
    const name = cart ? cart.name : "";
    const formattedPrice = formatCurrency(overallPrices.price);
    const countPriceStr = `${products.length} ${t(
      "orderLines"
    ).toLowerCase()}, ${formattedPrice}`;
    const validUntil = moment(expirationDate);
    const daysLeft = expirationDate
      ? validUntil.diff(moment(), "days") + 1
      : "-";
    const validDaysStr = `${t("validFor")} ${daysLeft} ${t(
      "validForDays"
    ).toLowerCase()}:`;
    const untilStr = expirationDate
      ? `${t("expires").toLowerCase()} ${validUntil
          .locale(i18n.language)
          .format("DD.MM.YYYY")}`
      : "-";

    return (
      <div className={classes.productsHeader}>
        <Typography
          variant="h4"
          color="textPrimary"
          className={classes.headerText}
          noWrap
        >
          {name}
        </Typography>
        <Hidden smDown>
          <Typography
            variant="h6"
            color="textPrimary"
            className={classes.headerText}
            noWrap
          >
            {countPriceStr}
          </Typography>
          <Progress
            className={classes.progress}
            show={sendingCart || sendingOrder}
            size={20}
          />
          <div className={classes.grow} />
          <Typography
            variant="body1"
            color="textPrimary"
            className={classes.validForText}
            noWrap
          >
            {`${validDaysStr} ${untilStr}`}
          </Typography>
        </Hidden>
      </div>
    );
  };

  const renderMemoControl = () => {
    const title = `${t("yourMemo")} (${t("optional").toLowerCase()})`;
    const placeholder = t("writeMemo");
    const info = t("thisVisibleOnlyYou");
    return (
      <div className={classes.contentContainer}>
        <MemoInput
          title={title}
          placeholder={placeholder}
          info={info}
          value={noteValue || ""}
          onChange={event => setNoteValue(event.target.value)}
          onBlur={event =>
            dispatch(
              cartSetNote(userId, selectedCart.cartId, event.target.value)
            )
          }
          maxLength={250}
        />
      </div>
    );
  };

  const resetSelectedCustomer = () => {
    // set default customer and shipping address
    const defaultCustomer =
      findCustomerById(defaultOrganizationId, validCustomers) ||
      validCustomers.find(c => organizationIds.includes(c.customerId));

    setSelectedCustomer(defaultCustomer);
  };

  const renderProducts = cart => {
    if (cart == null) {
      return null;
    }
    const products = cart.products || [];

    return (
      <div className={classes.contentContainer}>
        <Typography className={classes.cartText} variant="subtitle2" noWrap>
          {t("cart")}
        </Typography>
        <div className={classes.productsTable}>
          <CartProductTable
            products={products}
            productsCount={products.length}
            productPricesMap={productPricesMap}
            productStockStatusesMap={productStockStatusesMap}
            productAvailabilityStatusesMap={productAvailabilityStatusesMap}
            priceFetchIdMap={priceFetchIdMap}
            stockStatusFetchIdMap={stockStatusFetchIdMap}
            availabilityStatusFetchIdMap={availabilityStatusFetchIdMap}
            onChangeProductCount={(product, value) =>
              dispatch(cartSetProductCount(userId, cart.cartId, product, value))
            }
            onProductRemoved={productId =>
              dispatch(cartRemoveProduct(userId, cart.cartId, productId))
            }
            totalSumInfoText={getOrderSumInfoText()}
          />
        </div>
      </div>
    );
  };

  const renderColdStorageNotification = cart => (
    <div className={classes.contentContainer}>
      <ColdProductNotification products={cart ? cart.products : []} />
    </div>
  );

  const renderSaveOrderTemplatePopup = cart => (
    <SaveTemplatePopup
      show={showSaveTemplatePopup}
      top={popupPos.top - popupHeight}
      left={popupPos.left}
      onCreated={() => setShowSaveTemplatePopup(false)}
      onCancel={() => setShowSaveTemplatePopup(false)}
      popupRef={ref => {
        if (ref) {
          const rect = ref.getBoundingClientRect();
          if (popupHeight !== rect.height) {
            setPopupHeight(rect.height);
          }
        }
      }}
      cart={cart}
    />
  );

  const constructMissingProductsInfo = missingProducts => (
    <div className={classes.outOfStockModal}>
      <p>{t("partOfOrderOutOfStock")}</p>
      {missingProducts.map(missingProduct => (
        <p style={{ display: "block" }} key={missingProduct.product.materialId}>
          {getLocalizedProductName(missingProduct.product, i18n.language)}
        </p>
      ))}
    </div>
  );

  const renderConfirmOrderPopup = () => {
    const makeOrder = () => {
      // Show 'order in progress' modal
      const backordersAllowed = selectedCustomer.isBackordersAllowed;
      const outOfStockProducts = selectedCart.products.filter(product => {
        const stockMap = productStockStatusesMap.get(
          product.product.materialId
        );
        return !isInStock(stockMap);
      });
      dispatch(makingOrderDialogShow(t("processingOrder")));

      // validate fields
      const errors = validateOrder(
        selectedCart,
        backordersAllowed,
        outOfStockProducts,
        customers
      );
      if (errors.length === 0) {
        // create order data from cart data
        const orderData = createOrder(selectedCart);

        // make order
        dispatch(orderMakeOrder(orderData))
          .then(() => {
            // hide making order dialog
            dispatch(makingOrderDialogHide());

            // show success modal
            dispatch(
              notificationShow(t("productOrderingSuccess"), "", "", 6000)
            );

            // if backorders are not allowed and there is out of stock products in the cart
            if (!backordersAllowed && outOfStockProducts.length > 0) {
              // construct a list of products that have been excluded from the order
              const missingProductsInfo =
                constructMissingProductsInfo(outOfStockProducts);

              // show popup of the excluded products
              setShowErrorPopup({
                show: true,
                title: t("notice"),
                description: missingProductsInfo,
                hideOkButton: true,
                cancelKey: "close",
              });
            }
            // reload carts
            dispatch(cartInit(userId));
          })
          .catch(error => {
            // hide making order dialog
            dispatch(makingOrderDialogHide());

            // special address invalid error
            const status = (error || {}).status || 0;
            const addressExpired = status === HTTP_BAD_REQUEST_CODE;
            const cartNotFound = status === HTTP_NOT_FOUND_CODE;
            let description = "";
            if (addressExpired) {
              description = t("productOrderingFailedAddressExpired");
            } else if (cartNotFound) {
              description = t("productOrderingFailedNotFoundCart");
            } else {
              description = t("productOrderingFailed");
            }

            // show error popup if order fails
            setShowErrorPopup({
              show: true,
              title: t("error"),
              description,
              saveKey:
                addressExpired === false && !cartNotFound
                  ? t("retry")
                  : t("Ok"),
              hideOkButton: false,
              onOk: () => {
                // hide
                setShowErrorPopup(false);

                // retry only if the address is valid
                if (addressExpired === false && !cartNotFound) {
                  makeOrder();
                }
              },
            });
          });
      } else {
        dispatch(makingOrderDialogHide());
        const errorTexts = localizeValidationErrors(t, errors);
        // show error popup and list errors separated by ,
        setShowErrorPopup({
          show: true,
          title: t("error"),
          hideOkButton: true,
          description: errorTexts.join(", "),
        });
      }

      // hide
      setShowOrderConfirmationPopup(false);
    };

    const title = t("makeOrder");
    const description = t("makeOrderDescription");
    return (
      <ConfirmationPopup
        title={title}
        description={description}
        show={showOrderConfirmationPopup}
        onOk={() => makeOrder()}
        onCancel={() => setShowOrderConfirmationPopup(false)}
        saveKey="confirmOrder"
        iconType="shoppingCart"
        popupRef={ref => {
          if (ref) {
            const rect = ref.getBoundingClientRect();
            if (popupHeight !== rect.height) {
              setPopupHeight(rect.height);
            }
          }
        }}
      />
    );
  };

  const renderDeleteOrderTemplatePopup = () => {
    const onDelete = () => {
      dispatch(cartDelete(userId, selectedCart)).then(response => {
        // success?
        resetSelectedCustomer();
        if (response && response.status === 204) {
          setSelectedCart(null);
        }
      });
      setShowDeletePopup(false);
    };

    const title = t("deleteCart");
    const description = `${t("deleteDescription")} ${selectedCart.name}`;
    return (
      <ConfirmationPopup
        title={title}
        description={description}
        show={showDeletePopup}
        top={popupPos.top - popupHeight}
        left={popupPos.left}
        onOk={() => onDelete()}
        onCancel={() => setShowDeletePopup(false)}
        saveKey="delete"
        popupRef={ref => {
          if (ref) {
            const rect = ref.getBoundingClientRect();
            if (popupHeight !== rect.height) {
              setPopupHeight(rect.height);
            }
          }
        }}
      />
    );
  };

  const renderCustomerAndShippingAddressSelection = () => (
    <div className={classes.customerAndAddressContainer}>
      <CustomerAndShippingAddressSelect
        customers={validCustomers}
        className={classes.customerSelect}
        value={{
          customer: selectedCustomer,
          shippingAddress: selectedShippingAddress,
        }}
        onSelect={(customer, shippingAddress) => {
          setSelectedCustomer(customer);
          setSelectedShippingAddress(shippingAddress);

          const customerId =
            customer && customer.customerId ? customer.customerId : null;

          // update
          dispatch(
            cartSetCustomerAndShippingAddress(
              userId,
              selectedCart.cartId,
              customerId,
              (shippingAddress && shippingAddress.ShipTo) || null
            )
          );
          // Update prices on select
          const ids = selectedCart.products.map(
            cartProduct => cartProduct.product.materialId
          );
          if (ids.length) {
            dispatch(fetchProductPrices(customerId, ids));
          }
        }}
      />
    </div>
  );

  const renderDeliveryNote = () => {
    if (showDeliveryNote === true) {
      return (
        <div className={classes.deliveryNoteContainer}>
          <Info />
          <Typography className={classes.deliveryNote}>
            {t("shoppingCartDeliveryNote")}
          </Typography>
        </div>
      );
    }
    return null;
  };

  const renderBottomButtons = () => {
    const handlePopUpPos = () => {
      const rect = saveTemplateRef.getBoundingClientRect();
      setPopupPos({ top: rect.top, left: rect.left });
    };

    const showSavePopup = () => {
      handlePopUpPos();
      setShowSaveTemplatePopup(true);
    };

    // eslint-disable-next-line
    const showDeletePopup = () => {
      handlePopUpPos();
      setShowDeletePopup(true);
    };

    // eslint-disable-next-line
    const showOrderConfirmationPopup = () => {
      handlePopUpPos();
      setShowOrderConfirmationPopup(true);
    };

    const shoppingCartIsNotEmpty =
      selectedCart && selectedCart.products && selectedCart.products.length > 0;

    const orderButtonEnabled = () => {
      if (selectedCart != null && isOrderingCurrentlyAvailable) {
        const result = validateCartCustomerAndShippingAddress(
          selectedCart,
          customers
        );

        // has valid customer and products added
        const validCustomer =
          result.customerValid === true && result.shippingAddressValid === true;
        const orderSumIsValid = isCustomerOrderSumValid(
          selectedCustomer,
          overallPrices.price
        );
        return validCustomer && shoppingCartIsNotEmpty && orderSumIsValid;
      }
      return false;
    };

    const deleteKey = carts.length > 1 ? "delete" : "clear";
    const isOrderButtonEnabled = orderButtonEnabled();
    const orderButtonClass = isOrderButtonEnabled
      ? classes.orderButtonEnabled
      : classes.orderButtonDisabled;
    return (
      <div className={classes.buttonsContainer}>
        <div className={classes.buttons}>
          <Button
            className={classes.button}
            onClick={() => showSavePopup()}
            ref={setSaveTemplateRef}
            id="save-as-order-template-btn"
            disabled={!shoppingCartIsNotEmpty}
          >
            {t("saveAsOrderTemplate")}
          </Button>
          <Button
            className={`${classes.button} ${classes.deleteButton}`}
            onClick={() => showDeletePopup()}
            disabled={!shoppingCartIsNotEmpty}
          >
            {t(`${deleteKey}`)}
          </Button>
          <Button
            className={`${classes.button} ${orderButtonClass}`}
            onClick={() => showOrderConfirmationPopup()}
            disabled={!isOrderButtonEnabled}
            id="order-btn"
          >
            {t("order")}
          </Button>
        </div>
        {cartSendError && (
          <Typography className={classes.error} color="error">
            {`${t("networkError")} ${cartSendError.status}`}
          </Typography>
        )}
      </div>
    );
  };

  const renderBottomControls = () => (
    <div className={classes.contentContainer}>
      {renderCustomerAndShippingAddressSelection()}
      {renderDeliveryNote()}
      {renderBottomButtons()}
    </div>
  );

  const renderMakingOrderDialog = () => <MakingOrderDialog />;

  const renderErrorPopup = () => (
    <ErrorPopup
      title={errorPopup.title}
      description={errorPopup.description}
      show={errorPopup.show}
      onOk={errorPopup.onOk}
      onCancel={() => setShowErrorPopup(false)}
      cancelKey={errorPopup.cancelKey}
      saveKey={errorPopup.saveKey}
      hideOkButton={errorPopup.hideOkButton}
    />
  );

  const renderOrderingNotAvailableWarning = () => {
    const { from, to } = getOrderingNotAvailableBetweenHours();

    const text = t("orderingNotAvailable", {
      fromTime: from.format("HH:mm"),
      toTime: to.format("HH:mm"),
    });
    return (
      <div className={classes.orderingNotAvailableWarning}>
        <WarningMessage text={text} fullWidth />
      </div>
    );
  };

  return (
    <>
      {!isOrderingCurrentlyAvailable && renderOrderingNotAvailableWarning()}

      <Paper className={classes.products}>
        {renderHeader(selectedCart)}
        {renderMemoControl()}
        {renderProducts(selectedCart)}
        {renderColdStorageNotification(selectedCart)}
        {renderBottomControls()}
        {renderSaveOrderTemplatePopup(selectedCart)}
        {renderDeleteOrderTemplatePopup()}
        {renderConfirmOrderPopup()}
        {renderMakingOrderDialog()}
        {renderErrorPopup()}
      </Paper>
    </>
  );
}

ShoppingCart.propTypes = {
  selectedCart: PropTypes.shape({
    cartId: PropTypes.string,
    name: PropTypes.string,
    customer: PropTypes.string,
    shipToAddress: PropTypes.string,
    note: PropTypes.string,
    products: PropTypes.arrayOf(
      PropTypes.shape({
        product: PropTypes.shape({
          materialId: PropTypes.string,
        }),
        quantity: PropTypes.number,
      })
    ),
    expirationDate: PropTypes.string,
  }),
  setSelectedCart: PropTypes.func.isRequired,
};

ShoppingCart.defaultProps = {
  selectedCart: null,
};

export default ShoppingCart;
