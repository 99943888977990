import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { MAX_NUMBER_OF_SHOPPING_CARTS } from "../../../settings";
// eslint-disable-next-line import/no-cycle
import { cartCreate } from "../../../redux/reducers";
import { generateDefaultName } from "../../../utils/cartUtil/cartUtil";
import {
  findCustomerById,
  removeInvalidCustomersAndAddresses,
} from "../../../utils/customer/customer";
import {
  Progress,
  OrigoTextButton,
  MemoInput,
  CustomerAndShippingAddressSelect,
} from "../../generic";

const useStyles = makeStyles(theme => ({
  mainContainer: {
    width: "100%",
  },
  headerContainer: {
    paddingTop: theme.spacing(4),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
  },
  rowContainer: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
  },
  lastRowContainer: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
  },
  buttonsRow: {
    display: "flex",
    flexDirection: "row",
  },
  error: {
    marginTop: theme.spacing(1),
  },
  progress: {
    marginRight: theme.spacing(1),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    backgroundColor: "yellow",
  },
  fieldTitleText: {
    color: theme.palette.text.disabled,
    marginRight: theme.spacing(2),
  },
  controlContainer: {
    marginTop: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
  },
  textField: {
    marginRight: theme.spacing(2),
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: theme.spacing(0.5),
    },
  },
  customerSelect: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: theme.spacing(0.5),
    },
  },
}));

function NewShoppingCart({ onCreated, onCancel }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userId, organizationIds, defaultOrganizationId } = useSelector(
    state => state.user.userData
  );
  const { sendingCart, cartSendError, carts } = useSelector(
    state => state.cart
  );
  const customers = useSelector(state => state.customer.customers);
  const [validCustomers, setValidCustomers] = useState([]);
  const [name, setName] = useState(
    generateDefaultName(carts, t("shoppingCartDefaultName"))
  );
  const [memo, setMemo] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(undefined);
  const [selectedShippingAddress, setSelectedShippingAddress] =
    useState(undefined);

  useEffect(() => {
    // eslint-disable-next-line
    const validCustomers = removeInvalidCustomersAndAddresses(customers);
    setValidCustomers(validCustomers);

    // set default customer and shipping address
    const defaultCustomer =
      findCustomerById(defaultOrganizationId, validCustomers) ||
      validCustomers.find(c => organizationIds.includes(c.customerId));
    setSelectedCustomer(defaultCustomer);

    if (defaultCustomer?.deliveryAddresses?.length) {
      const defaultShippingAddress =
        defaultCustomer.deliveryAddresses.find(
          address => address.ShipTo === defaultCustomer.customerId
        ) || defaultCustomer.deliveryAddresses[0];
      setSelectedShippingAddress(defaultShippingAddress);
    }
  }, [customers, organizationIds, defaultOrganizationId, setValidCustomers]);

  const renderHeader = () => {
    const headerText = t("addNewCart");

    return (
      <div className={classes.headerContainer}>
        <Typography variant="h4" color="textPrimary" noWrap>
          {headerText}
        </Typography>
      </div>
    );
  };

  const renderNameInput = () => (
    <div className={classes.rowContainer}>
      <Typography className={classes.fieldTitleText} variant="subtitle2" noWrap>
        {t("name")}
      </Typography>
      <div className={classes.controlContainer}>
        <TextField
          value={name}
          variant="outlined"
          placeholder={t("name")}
          className={classes.textField}
          margin="dense"
          onChange={event => setName(event.target.value)}
          inputProps={{ maxLength: 30 }}
        />
      </div>
    </div>
  );

  const renderMemoControl = () => {
    const title = `${t("yourMemo")} (${t("optional").toLowerCase()})`;
    const placeholder = t("writeMemo");
    const info = t("thisVisibleOnlyYou");
    return (
      <div className={classes.rowContainer}>
        <MemoInput
          title={title}
          placeholder={placeholder}
          info={info}
          onChange={event => setMemo(event.target.value)}
          maxLength={250}
        />
      </div>
    );
  };

  const renderCustomerAndShippingAddressSelection = () => (
    <div className={classes.rowContainer}>
      <CustomerAndShippingAddressSelect
        customers={validCustomers}
        className={classes.customerSelect}
        value={{
          customer: selectedCustomer,
          shippingAddress: selectedShippingAddress,
        }}
        onSelect={(customer, shippingAddress) => {
          setSelectedCustomer(customer);
          setSelectedShippingAddress(shippingAddress);
        }}
      />
    </div>
  );

  const isSaveDisabled = () => {
    // disable creating a cart if the name is invalid, cart limit reached or no valid customer & shipping address is set
    const cartInvalid =
      !name || (carts || []).length >= MAX_NUMBER_OF_SHOPPING_CARTS;
    const customerAndAddressInvalid =
      selectedCustomer == null || selectedShippingAddress == null;
    return cartInvalid === true || customerAndAddressInvalid === true;
  };

  const saveCart = () => {
    const cartData = {
      name,
      note: memo,
      customer: selectedCustomer ? selectedCustomer.customerId : undefined,
      shipToAddress: selectedShippingAddress
        ? selectedShippingAddress.ShipTo
        : undefined,
    };

    dispatch(cartCreate(userId, cartData)).then(createdCart => {
      if (createdCart) {
        onCreated(createdCart);
      }
    });
  };
  const renderButtons = () => (
    <div className={classes.lastRowContainer}>
      <div className={classes.buttonsRow}>
        <OrigoTextButton disabled={sendingCart} onClick={onCancel}>
          {t("cancel")}
        </OrigoTextButton>
        <OrigoTextButton
          color="secondary"
          disabled={isSaveDisabled() || sendingCart}
          onClick={saveCart}
        >
          {t("save")}
        </OrigoTextButton>
        <Progress show={sendingCart} />
      </div>
      {cartSendError && (
        <Typography className={classes.error} color="error">
          {`${t("networkError")} ${cartSendError.status}`}
        </Typography>
      )}
    </div>
  );

  return (
    <Paper className={classes.mainContainer}>
      {renderHeader()}
      {renderNameInput()}
      {renderMemoControl()}
      {renderCustomerAndShippingAddressSelection()}
      {renderButtons()}
    </Paper>
  );
}

NewShoppingCart.propTypes = {
  onCreated: PropTypes.func,
  onCancel: PropTypes.func,
};

NewShoppingCart.defaultProps = {
  onCreated: () => {}, // eslint-disable-line
  onCancel: () => {}, // eslint-disable-line
};

export default NewShoppingCart;
